<template>
  <b-button class="btn-modal-confirm" @click="getWsToken">试玩</b-button>
</template>

<script>
import tryGame from "@/components/test/TryGame"
export default {
  name: 'Trial',
  methods: {
    getWsToken () {
      tryGame.requestWsToken();
    }
  }
};
</script>