import API from "@/api/api";
export default {
    requestWsToken,
    tryCloudGame
}

function requestWsToken (){
    const headers = {
        "TCX-Sign": "b73da3527b2695b03ce4215443c04102",
    };
    API.getTryWsToken({client_id:'test', game_id:903, launch_type:'try_play_game'}, {headers})
        .then(response => {
            let res = response.data;
            console.log('第三方获取wsToken返回的数据:' + JSON.stringify(response.data));
            if (res.status) {
                let ws_token = res.data.access_token;
                console.log('已获取到ws_token: ' + ws_token);
                window.open('/#/trial?ws_token=' + ws_token);
            }
        })
        .catch(error => {
            console.log('第三方获取ws_token请求失败: ' + error);
            // onFailed('身份验证失败:' + error);
        });
}

function tryCloudGame(gameId, onGameInitParams, requestStartCloudGame){
    const headers = {
        "app-id": "cn.dygame.web",
    };
    API.getStartCloudGame({client_id:'test', game_id:gameId, type:'try_play_game'}, {headers})
        .then(response => {
            let res = response.data;
            console.log('第三方获取wsToken返回的数据:' + JSON.stringify(response.data));
            if (res.status) {
                let wsToken = res.data.ws_token.token;
                console.log('已获取到ws_token: ' + wsToken);
                let wsServer = res.data.ws_token.ws_server;
                let videoWidth = res.data.game_params.video_width;
                let videoHeight = res.data.game_params.video_height;
                let gameName = res.data.game_params.name;
                let recharge = res.data.game_params.recharge;
                let gameTimeCount = res.data.player_info.gaming_duration || 0;
                onGameInitParams(videoWidth, videoHeight, gameTimeCount, gameName, recharge);
                requestStartCloudGame(gameId, wsToken, wsServer);
            }
        })
        .catch(error => {
            console.log('第三方获取ws_token请求失败: ' + error);
            // onFailed('身份验证失败:' + error);
        });
}